@import "../variables";

/**
  In case of no Javascript we need the site to be still readable
*/
.no-js {
  .sections-container {
    position: relative;
  }

  .section {
    position: relative;

    &:last-child {
      height: 100vh;
    }
  }
}

.sections-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: block;
}

body.loaded {
  .section {
    position: absolute;
  }
}

@keyframes sectionsIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.section {
  top: 0;
  left: 0;
  height: 65vh;
  width: 100%;
  margin: 0;
  display: block;
  box-sizing: border-box;
  padding: $header-height + 45px 0 0 $grid-gutter;
  list-style: none;

  opacity: 1;
  animation: sectionsIn 1s linear;
  transform-origin: top left;

  @media (min-width: $bp-lg) {
    height: 55vh;
    padding-top: 20vh;
    padding-left: 0;
  }
}

.section__title {
  position: relative;
  margin-top: 0;

  &:before {
    position: absolute;
    top: .85em;
    left: -2em;
    display: block;
    color: $text-color;
    font-family: $font-family;
    font-size: 18px;
    font-weight: $font-weight-base;
    line-height: 1em;
  }
}

.section__content {
  margin-left: 50px;
  max-width: 445px;

  @media (min-width: $bp-lg) {
    margin-left: 170px;
  }

  &--contact {
    max-width: 100%;
  }
}

/**
 * Sections counter in titles
 */
body {
  counter-reset: sectionCount;
}

.section__title--ordered {
  counter-increment: sectionCount;

  &:before {
    content: "0" counter(sectionCount);
  }
}

.section__title--ordered-zero {
  &:before {
    content: "00";
  }
}
