@import "../variables";

.highlight {
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.85em;
    margin-top: .22em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    margin-left: -0.1em;
    margin-right: -0.1em;
    background-image: url(../img/noise-bg.png);
    background-color: $body-bg;
  }
}
