@import "variables";

html, body {
  background-color: $body-bg;
}

#particles {
  @media (max-width: $bp-lg) {
    background-image: url(../img/particles-bg-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: .8;
  }
}

::selection {
  background-color: $highlight-color;
}
