@import "../variables";

.hire-cta {
  text-align: right;
  line-height: 1em;
}

.hire-cta__title {
  font-weight: $font-weight-strong;

  @media (min-width: $bp-lg) {
    margin: 0 0 .5em;
  }
}

.hire-cta__text {
  margin: 0 0 .3em;

  @media (max-width: $bp-lg - 1) {
    display: none;
  }
}

.hire-cta__link {
  @media (max-width: $bp-lg - 1) {
    &:hover, & {
      font-size: $font-size-base;
      box-shadow: rgba($link-color, .5) 0px -1px 0 inset;
    }
  }
}
