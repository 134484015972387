@import "../variables";

.contact-data {
  @media (min-width: $bp-lg) {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 2em;
    padding-right: 20px;
  }
}

.contact-data__address {
  font-style: normal;
  margin-bottom: 2em;
}
