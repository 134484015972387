.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  border-bottom: 1px solid $neutral-lighten;
  background-color: rgba($body-bg, .98);

  @media (max-width: $bp-lg - 1) {
    padding: 0 $grid-half-gutter;
  }
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;

  @media (max-width: $bp-lg - 1) {
    max-width: $container-width;
  }

  @media (min-width: $bp-lg) {
    height: $header-height-lg;
  }
}

.header__logo {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: $header-height;
  color: $neutral-light;

  // not using BEM name for image because the image is inlined
  // into HTML via a nunjucks method so we can't add class on it
  > svg {
    width: 132px;
    height: 20px;
  }

  @media (min-width: $bp-lg) {
    width: auto;
    height: auto;

    > svg {
      width: 112px;
      height: 17px;
    }
  }
}

.header__aside {
  width: 100%;

  @media (min-width: $bp-lg) {
    width: auto;
  }
}
