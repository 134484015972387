@import "../variables";

a,
a:visited {
  color: $link-color;
  text-decoration: none;

  box-shadow: rgba($link-color, .5) 0px -1px 0 inset;
  transition: box-shadow 0.2s ease-in-out 0s;
}

a {
  &:hover,
  &:focus {
    box-shadow: $link-color-hover 0px -1.2em 0 inset;
    transition: box-shadow 0.2s ease-in-out 0s;
    outline: none;
  }
}

/**
 * Remove all effect of `<a>` link on an element that should not be
 * change style on hover as a brand logo with a link.
 */
.no-link {
  &, &:hover, &:focus, &:visited {
    color: inherit;
    text-decoration: none;
    box-shadow: none;
    transition: none;
  }
}
