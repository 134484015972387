@import "../variables";

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  padding-bottom: 4em;
  pointer-events: none;
  z-index: 99;

  @media (max-width: $bp-lg - 1) {
    padding-bottom: 1.3em;
  }
}

.footer__link {
  position: relative; // Fix ie11: make links available for click
  pointer-events: auto;
}
