@import "../variables";
@import "../mixins";

html, body {
  @include font-smoothing();
  color: $text-color;
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;

  @media (min-width: $bp-lg) {
    font-size: $font-size-base-lg;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $text-color-heading;
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
  line-height: $line-height-heading;
}

h1, .h1 {
  font-size: $font-size-h1;

  @media (min-width: $bp-lg) {
    font-size: $font-size-h1-lg;
  }
}

h2, .h2 {
  font-size: $font-size-h2;
  @media (min-width: $bp-lg) {
    font-size: $font-size-h2-lg;
  }
}

h3, .h3 {
  font-size: $font-size-h3;
  text-transform: uppercase;

  @media (min-width: $bp-lg) {
    font-size: $font-size-h3-lg;
  }
}

strong, b {
  font-weight: $font-weight-strong;
}

p {
  margin-top: 1em;
  margin-bottom: 2em;
}
