@use "sass:math";

// COLORS
// Neutrals
$neutral-dark: #191919 !default;
$neutral-lighten: #2E2E2E !default;
$neutral-light: #fff !default;
// Colorful
$red: #b90000 !default;
$blue: #004DFF !default;
$green: #00AD73 !default;
$orange: #b99300 !default;
// Project colors
$brand-color: $green !default;
$highlight-color: $brand-color !default;
// Element colors
$body-bg: $neutral-dark !default;

$text-color: rgba($neutral-light, .9) !default;
$text-color-heading: $neutral-light !default;

$link-color: $neutral-light !default;
$link-color-hover: $highlight-color !default;
$link-color-visited: inherit !default;

// BREAKPOINTS
$bp-lg: 768px !default;

// FONTS
$fonts-path: "../fonts/" !default;
$font-family: "Roboto Mono", Verdana, sans-serif !default;
$font-family-heading: "Open Sans", Helvetica, Arial, sans-serif !default;

// TYPOGRAPHY SIZES
$font-weight-base: normal !default;
// Roboto Mono Bold = 700
$font-weight-strong: 700 !default;
// Open Sans Semi-bold = 600
$font-weight-heading: 600 !default;

$font-size-base: 13px !default;
$font-size-base-lg: $font-size-base !default;

$font-size-h1: 50px !default;
$font-size-h1-lg: 90px !default;

$font-size-h2: 30px !default;
$font-size-h2-lg: 58px !default;

$font-size-h3: 10px !default;
$font-size-h3-lg: 14px !default;

$line-height-base: 1.77em !default;
$line-height-heading: 1.1em !default;

// GRID
$grid-gutter: 30px;
$grid-half-gutter: math.div($grid-gutter, 2);

$container-width: 500px;
$container-width-lg: 920px + $grid-gutter;

// Header
$header-height: 60px;
$header-height-lg: 95px;

$hire-cta-height: 56px;
