@import "../variables";

button,
.btn {
  display: inline-block;
  padding: 16px 35px;
  border: none;
  color: $neutral-light;
  font-family: $font-family;
  font-size: $font-size-base-lg;

  cursor: pointer;
  box-shadow: $text-color 0 0 0 inset;
  background-color: $brand-color;
  transition: color, box-shadow .3s;

  &:focus {
    outline: none;
  }

  @media (min-width: $bp-lg) {
    &:hover,
    &:focus:hover {
      color: $neutral-dark;
      box-shadow: $text-color 0 -4.5em 0 inset;
      transition: color, box-shadow .3s;
    }

    &:active {
      transform: scale(.98);
    }

    &:focus {
      box-shadow: $text-color 0 -.5em 0 inset;
    }
  }
}

.section-nav-btn {
  position: fixed;
  width: 58px;
  height: 58px;
  padding: 0;
  text-align: center;
  opacity: 1;
  transition: opacity .2s ease;
  background: transparent;
  z-index: 999;

  &__icon {
    width: 20px;
    height: 20px;
  }

  &--next {
    bottom: 0;
    right: 0;
  }

  &--prev {
    top: $header-height;
    left: 0;

    > .section-nav-btn__icon {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s ease;
  }

  @media (min-width: $bp-lg) {
    &__icon {
      width: 30px;
      height: 30px;
    }

    &--prev {
      top: $grid-half-gutter + $header-height-lg;
      left: $grid-half-gutter;
    }

    &--next {
      bottom: $grid-half-gutter;
      right: $grid-half-gutter;
    }
  }
}
