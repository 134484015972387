@import "../variables";

.container {
  display: block;
  width: 100%;
  max-width: $container-width;
  box-sizing: border-box;
  padding-right: $grid-half-gutter;
  padding-left: $grid-half-gutter;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $bp-lg) {
    max-width: $container-width-lg;
  }
}
